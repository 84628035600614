<template>
    <div class="payment_channels">
        <Top  name="收银台" email back></Top>
        <div class="payment_channels_sum">￥{{paymoney}}</div>
        <div class="tip">提示：当前可用余额{{accountBalance}}元，余额不足！</div>
        <div class="payment_channels_text">请选择支付方式：</div>
        <van-checkbox-group v-model="radio" @change="payChange">
            <ul class="payment_channels_list">
                <!-- <li>
                    <span>
                        <img src="../../assets/images/wechat_icon.png">
                        <span>微信支付</span>
                    </span>
                    <van-radio name="wx"></van-radio>
                </li> -->
                <li>
                    <!-- <span> -->
                        <img src="../../assets/images/alipay_pay.png">
                        <span>支付宝支付</span>
                    <!-- </span> -->
                    <van-checkbox name="ali"></van-checkbox>
                </li>
            </ul>
        </van-checkbox-group>
        <div class="payment_channels_btn"><van-button round :disabled='radio.indexOf("ali")==-1' color="linear-gradient(to right, rgb(255, 112, 77), rgb(247, 55, 39))" size="large" @click="paySubmit">组合支付</van-button></div>
    </div>
</template>
<script>
import Top from '@/components/top'
import {debounce} from "@/assets/js/utils.js"

export default {
    name:"paymentChannels",
    data(){
        return {
            radio: ['ali'],
            accountBalance:'',
            paymoney: '',
            params: {},
            payName: '微信',
            ip: '',
            click: '',
            debounce,
        }
    },
    components: {
      Top
    },
    created() {
        console.log(123)
        //清除支付宝支付留下的元素
        let divnewForm = document.getElementsByTagName('divnewform');
        // console.log(divnewForm)
        if (divnewForm.length) {
            document.body.removeChild(divnewForm[0]);
        }
        //获取路由参数
        this.params = this.$route.query;
        this.paymoney = this.params.number;
        this.ip = localStorage.getItem('ip');
        // console.log(this.ip)
        let isPay = localStorage.getItem('isPay');
        // console.log(window.history.length)
        this.getBalance()
        if (isPay == 'true' || window.history.length <= 2) {
            
            this.checkOrder();
        }
        //防抖动
        this.click = this.debounce(this.payRequest, 500);
    },
    methods: {
        //支付方式切换
        payChange(val) {
            // if (val == 'wx') {
            //     this.payName = '微信';
            // }else{
            //     this.payName = '支付宝';
            // }
        },
         //获取账户余额
        getBalance() {
            let username = localStorage.getItem('username');
            this.$api.account.getBalance({username:username}).then((res)=>{
                if (res.code == 200) {
                    this.accountBalance = res.data.accountBalance
                    
                }
            }).catch(err => {
                console.log(err);
            });
        },
        //提交支付
        paySubmit() {
            this.click();
        },
        //查看支付是否成功
        checkOrder() {
            //每2s查询一次，直到获取到结果为止
            let timer;
            timer = setInterval(() => {
                this.$api.shopping.checkOrder({
                    goods_id: this.params.goods_id,
                }).then((res)=>{
                    //支付宝支付拿到结果后结束轮询
                    clearInterval(timer);
                    //针对微信支付拿到结果后清除存的值
                    localStorage.removeItem('isPay');
                    if (res.code == 200) {
                        // this.$router.push({
                        //     name: 'Paysuccess',
                        // });
                        this.$toast({
                            duration: 1500,
                            message: '购买成功'
                        })
                        this.$router.push({
                            path: '/'
                        });
                    }
                }).catch(err => {
                    console.log(err);
                });
            }, 2000);
        },
        //提交支付请求
        payRequest() {
            //微信支付成功后会新窗口打开页面，所以用本地存值的方法来判断是否发起订单结果请求
            localStorage.setItem('isPay', true);
            let paytype, isbalance;
            paytype = this.radio == 'wx' ? 'wx' : 'ali';
            isbalance = this.params.checked == 1 ? 1 : 0;
            if (paytype == 'wx') {
                let wxparam, contents, tok;
                tok = localStorage.getItem('token').substring(7);
                if (this.params.to_server) {
                    wxparam = {
                        username: localStorage.getItem('username'),
                        goods_id: this.params.goods_id,
                        pay_type: 'wxpay',
                        type: 'wap',
                        is_balance: isbalance,
                        ip: this.ip,
                        token: tok,
                        to_server: this.params.to_server,
                        chrname: this.params.chrname
                    };
                } else {
                    wxparam = {
                        username: localStorage.getItem('username'),
                        goods_id: this.params.goods_id,
                        pay_type: 'wxpay',
                        type: 'wap',
                        is_balance: isbalance,
                        ip: this.ip,
                        token: tok
                    };
                }
                contents = this.$Base64.encode(JSON.stringify(wxparam));
                this.$api.shopping.createOrderAli({
                    content: contents
                }).then(res => {
                    //console.log(res);
                    //清除支付宝支付留下的元素
                    let divnewForm = document.getElementsByTagName('divnewform');
                    if (divnewForm.length) {
                        document.body.removeChild(divnewForm[0]);
                    }

                    let divWxForm = document.getElementsByTagName('divwxform');
                    if (divWxForm.length) {
                        document.body.removeChild(divWxForm[0]);
                    }
                    const div = document.createElement('divwxform');
                    div.innerHTML = res; // data就是接口返回的form 表单字符串
                    document.body.appendChild(div);
                    //document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
                    document.forms[0].submit();
                }).catch(err => {
                    console.log(err);
                });
            }else{
                //支付宝支持成功后会返回到原界面，所以支付后原界面需要通过轮询的方法来判断是否发起订单结果请求
                let param, contents, tok;
                tok = localStorage.getItem('token').substring(7);
                if (this.params.to_server) {
                    param = {
                        username: localStorage.getItem('username'),
                        goods_id: this.params.goods_id,
                        pay_type: 'alipay',
                        type: 'wap',
                        is_balance: isbalance,
                        token: tok,
                        to_server: this.params.to_server,
                        chrname: this.params.chrname
                    };
                } else {
                    param = {
                        username: localStorage.getItem('username'),
                        goods_id: this.params.goods_id,
                        pay_type: 'alipay',
                        type: 'wap',
                        is_balance: isbalance,
                        token: tok
                    };
                }
                // console.log(param)
                contents = this.$Base64.encode(JSON.stringify(param));
                this.$api.shopping.createOrderAli({
                    content: contents
                }).then(res => {
                    //存放原始数据
                    let divForm = document.getElementsByTagName('divform');
                    if (divForm.length) {
                        document.body.removeChild(divForm[0]);
                    }
                    //存放提取出来的form表单
                    let divnewForm = document.getElementsByTagName('divnewform');
                    if (divnewForm.length) {
                        document.body.removeChild(divnewForm[0]);
                    }
                    const div = document.createElement('divform');
                    div.innerHTML = res; // data就是接口返回的form 表单字符串
                    document.body.appendChild(div);
                    //将提取出来的form元素放到新元素里
                    let divs = document.createElement('divnewform');
                    divs.innerHTML = '';
                    divs.appendChild(document.forms[0]);
                    document.body.appendChild(divs);
                    // console.log(divs);
                    // return
                    //删除原始元素
                    document.body.removeChild(divForm[0]);
                    //console.log(document.forms[0]);
                    //document.forms[0].setAttribute('target', '_blank'); // 新开窗口跳转
                    document.forms[0].submit();
                //     console.log(divForm);
                // return 
                    setTimeout(() => {
                        this.checkOrder();
                    }, 800);
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    },
    beforeDestroy() {
        this.debounce=null;//清除闭包
        // console.log('销毁了')
    }
    
}
</script>
<style scoped>
.payment_channels_sum{
    color: #ff4e00;
    font-size: 1.8542rem;
    line-height: 1.8542rem;
    padding: 2.2292rem 0 2.2917rem;
    
}
.tip{
    font-size: .9375rem;
    color: #ff4e00;
    line-height: .9375rem;
    padding-bottom: 1.125rem;
    border-bottom: .0417rem solid #DEDEDE;
}
.payment_channels_text{
    text-align: center;
    font-size: .8958rem;
    line-height: .8958rem;
    padding: 1.9167rem 0 1.4167rem;
}
.payment_channels_list{
    min-height: 400px;
}
.payment_channels_list li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.1042rem;
    background: #efefef;
    border: .0417rem solid #d8d8d8;
    width: 91%;
    margin: 0 auto;
    border-radius: .625rem;
    padding: 0 1.125rem;
}
.payment_channels_list li>img{
    width: 1.9375rem;
    height: 1.9375rem;
    vertical-align: middle;
}
.payment_channels_list li>span{
    font-size: 1.0833rem;
    vertical-align: middle;
    color: #19A0E5;
}
.payment_channels_btn{
    height: 8rem;
    background: #fff;
    /* border-top: 1px solid #dedede; */
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
}
.payment_channels_btn button{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    transform: translate(-50%,-50%);
}
</style>